import axios from 'axios'
import GlobalVariable from './GlobalVariable.js'
import Store from '../store/index.js'
const API_URL = GlobalVariable.baseUrl
const token = sessionStorage.getItem('token')
export class APIService {
    checkAuth (authToken) {
        const url = `${API_URL}/admin/check_auth`
        return axios.get(url, { headers: { token: authToken } })
    }
    updatePassword (passwordJson, passwordToken) {
        const url = `${API_URL}/admin/update_password`
        return axios.post(url,passwordJson,{ headers: { token: passwordToken } })
    }
    getRoles () {
        const url = `${API_URL}/admin/roles`
        return axios.get(url, { headers: { token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    createAdmin (adminJson) {
        const url = `${API_URL}/admin/register`
        return axios.post(url, adminJson ,{ headers: { token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    updateAdmin (adminJson) {
        const url = `${API_URL}/admin/update`
        return axios.post(url, adminJson ,{ headers: { token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    getAdminList (adminJson) {
        const url = `${API_URL}/admin/list`
        return axios.post(url, adminJson ,{ headers: { token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    adminLogin (userJson) {
        const url = `${API_URL}/admin/login`
        return axios.post(url, userJson)
    }
    addEvent (eventJson) {
        const url = `${API_URL}/event/add`
        return axios.post(url, eventJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    getEventDetails (id) {
        const url = `${API_URL}/event/details/${id}`
        return axios.get(url,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    deleteEvent (id) {
        const url = `${API_URL}/event/delete/${id}`
        return axios.get(url,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    editEvent (eventJson) {
        const url = `${API_URL}/event/edit`
        return axios.post(url, eventJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    getEventImages (eventJson) {
        const url = `${API_URL}/event/images`
        return axios.post(url, eventJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    deleteEventImages (eventJson) {
        const url = `${API_URL}/event/images/delete`
        return axios.post(url, eventJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    addEventImages (eventJson) {
        const url = `${API_URL}/event/images/add`
        return axios.post(url, eventJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data'  } })
    }
    getEvents (eventJson) {
        const url = `${API_URL}/event`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    // Departments

    addDepartment (deptJson) {
        const url = `${API_URL}/departments/add`
        return axios.post(url, deptJson,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data' } })
    }
    editDepartment (deptJson) {
        const url = `${API_URL}/departments/edit`
        return axios.post(url, deptJson,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data' } })
    }
    parentDepartments () {
        const url = `${API_URL}/departments/parents`
        return axios.get(url, { headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    getSubDepartments (parentId) {
        const url = `${API_URL}/departments/${parentId}`
        return axios.get(url, { headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    getDepartments (departmentId) {
        const url = `${API_URL}/departments/details/${departmentId}`
        return axios.get(url, { headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    deleteDepartments (departmentId) {
        const url = `${API_URL}/departments/delete/${departmentId}`
        return axios.get(url, { headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }

    // Department End

    // Banners

    addBanners (bannerJson) {
        const url = `${API_URL}/banner/add`
        return axios.post(url, bannerJson,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data' } })
    }
    getBanners () {
        const url = `${API_URL}/banner`
        return axios.get(url,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    deleteBanner (id) {
        const url = `${API_URL}/banner/delete/${id}`
        return axios.get(url,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    // Banner End
    // Faculties

    addFaculty (deptJson) {
        const url = `${API_URL}/faculties/add`
        return axios.post(url, deptJson,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data' } })
    }
    editFaculty (deptJson) {
        const url = `${API_URL}/faculties/edit`
        return axios.post(url, deptJson,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data' } })
    }
    getFaculties (parentId) {
        const url = `${API_URL}/faculties/${parentId}`
        return axios.get(url, { headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    deleteFaculty (departmentId) {
        const url = `${API_URL}/faculties/delete/${departmentId}`
        return axios.get(url, { headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }

    // Faculties End

    // Non Teaching Staff

    addStaff (deptJson) {
        const url = `${API_URL}/non_teaching/add`
        return axios.post(url, deptJson,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data' } })
    }
    editStaff (deptJson) {
        const url = `${API_URL}/non_teaching/edit`
        return axios.post(url, deptJson,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data' } })
    }
    getStaff () {
        const url = `${API_URL}/non_teaching`
        return axios.get(url, { headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    deleteStaff (staffId) {
        const url = `${API_URL}/non_teaching/delete/${staffId}`
        return axios.get(url, { headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }

    // Scholarships
    addScholarship (courseJson) {
        const url = `${API_URL}/scholarship/store`
        return axios.post(url, courseJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    getScholarships () {
        const url = `${API_URL}/scholarship`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    // Scholarships Link
    addScholarshipLink (courseJson) {
        const url = `${API_URL}/scholarship_link/store`
        return axios.post(url, courseJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token, 'Content-Type': 'multipart/form-data' } })
    }
    deleteScholarship (linkId) {
        const url = `${API_URL}/scholarship/delete/${linkId}`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    deleteScholarshipLink (linkId) {
        const url = `${API_URL}/scholarship_link/delete/${linkId}`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    getScholarships () {
        const url = `${API_URL}/scholarship`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    // Non Teaching Staff End

    getImages (courseJson) {
        const url = `${API_URL}/admin/gallery`
        return axios.post(url, courseJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    deleteImage (courseJson) {
        const url = `${API_URL}/admin/gallery/delete`
        return axios.post(url, courseJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    getContacts (contactJson) {
        const url = `${API_URL}/admin/contacts`
        return axios.post(url, contactJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    resolveQuery (contactJson) {
        const url = `${API_URL}/admin/contacts/resolve`
        return axios.post(url, contactJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    uploadImage (imageJson) {
        const url = `${API_URL}/admin/gallery/add`
        return axios.post(url, imageJson, { headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token, 'Content-Type': 'multipart/form-data' } })
    }
    getUserIp () {
        const url = `https://api.country.is`
        return axios.get(url)
    }

// Circulars Start 
    getCirculars (page) {
        const url = `${API_URL}/circulars/admin/${page}`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    getInitialCirculars () {
        const url = `${API_URL}/circulars/admin`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    deleteCircular (id) {
        const url = `${API_URL}/circulars/delete/${id}`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    addCircular (tempJson) {
        const url = `${API_URL}/circulars/store`
        return axios.post(url, tempJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data' } })
    }
    addIQAC (tempJson) {
        const url = `${API_URL}/iqac/store`
        return axios.post(url, tempJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data' } })
    }
    getIQAC (page) {
        const url = `${API_URL}/iqac/${page}`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    deleteIQAC (id) {
        const url = `${API_URL}/iqac/delete/${id}`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }

    addGP (tempJson) {
        const url = `${API_URL}/gp/store`
        return axios.post(url, tempJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data' } })
    }
    getGP (page) {
        const url = `${API_URL}/gp/${page}`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }
    deleteGP (id) {
        const url = `${API_URL}/gp/delete/${id}`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token } })
    }

    addCriteria (tempJson) {
        const url = `${API_URL}/criteria/store`
        return axios.post(url, tempJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token} })
    }
    getCriteria () {
        const url = `${API_URL}/criteria`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token} })
    }
    deleteCriteria (id) {
        const url = `${API_URL}/criteria/delete/${id}`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token} })
    }

    addCriteriaFile (tempJson) {
        const url = `${API_URL}/criteria_files/store`
        return axios.post(url, tempJson ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token,  'Content-Type': 'multipart/form-data'} })
    }
    getCriteriaFiles (id) {
        const url = `${API_URL}/criteria_files/${id}`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token} })
    }
    deleteCriteriaFile (id) {
        const url = `${API_URL}/criteria_files/delete/${id}`
        return axios.get(url ,{ headers: {  token: Store.state.adminDetails.token == undefined ? token:Store.state.adminDetails.token} })
    }
}

