<template>
    <v-container>
        <v-row class="mt-8">
            <v-col class="text-center" cols="12">
                <h2>Welcome to admin panel of Govt. College Karauli</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" to="/admins">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-account</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">Admins</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" to="/departments">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-book</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">Departments</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" to="/event">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-image</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">Events</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" to="/non_teaching">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-account-tie-outline</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">Non teaching staff</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" to="/admins">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-face-agent</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">Admins</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" to="/banners">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-image-area</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">Banners</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" to="/scholarships">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-book-education</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">Scholarship</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" to="/circulars">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-pdf-box</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">Circulars</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" to="/iqac">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-file-chart</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">IQAC</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" to="/pdfs">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-file-cabinet</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">Other PDFS</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" to="/criteria">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-paperclip</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">DVV Criteria</v-card-title>
                </v-card>
            </v-col>
            <v-col cols="12" lg="2" md="4" sm="6">
                <v-card color="primary" @click="logout()">
                    <v-card-text class="text-center">
                        <v-icon size="150" color="white">mdi-logout</v-icon>
                    </v-card-text>
                    <v-card-title class="text-center">Logout</v-card-title>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { defineComponent } from 'vue';
// Components
export default defineComponent({
    methods: {
    logout() {
      sessionStorage.removeItem('token')
      this.$store.state.isAuthenticated = false
      this.$router.push('/login')
    }
  },
});
</script>
