import { createStore } from 'vuex'

export default createStore({
  state: {
    isAuthenticated: false,
    adminDetails: {
      name: null,
      email: null,
      token: null
    },
    adminRole: null,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
