import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Store from '../store/index.js'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/event',
    name: 'EventGallery',
    component: () => import('../views/EventGallery.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/event/:id',
    name: 'EventGalleryDetails',
    component: () => import('../views/EventDetails.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/departments',
    name: 'Deprtments',
    component: () => import('../views/Departments.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/department/:id',
    name: 'Faculty',
    component: () => import('../views/Faculty.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/subjects/:departmentId',
    name: 'Subjects',
    component: () => import('../views/Subjects.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/non_teaching',
    name: 'NonTeaching',
    component: () => import('../views/NonTeachingStaff.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/criteria',
    name: 'Criteria',
    component: () => import('../views/DVVCriteria.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/criteria/:id',
    name: 'CriteriaFiles',
    component: () => import('../views/CriteriaFiles.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/circulars',
    name: 'Circulars',
    component: () => import('../views/Circulars.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/iqac',
    name: 'IQAC',
    component: () => import('../views/IQAC.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/scholarships',
    name: 'Scholarship',
    component: () => import('../views/Scholarship.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/banners',
    name: 'Banners',
    component: () => import('../views/Banners.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/pdfs',
    name: 'GeneralPdf',
    component: () => import('../views/GeneralPdf.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      tokenNotAvailable: true,
    }
  },
  {
    path: '/admins',
    name: 'Admins',
    component: () => import('../views/AdminView.vue'),
    meta: {
      AdminRole: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (sessionStorage.getItem('token') === null || sessionStorage.getItem('jwt') === 'null') {
      next({
        path: '/login',
        // params: { nextUrl: to.fullPath }
      })
    }
  } if (to.matched.some(record => record.meta.tokenNotAvailable)) {
    if (sessionStorage.getItem('token') == null || sessionStorage.getItem('jwt') == 'null') {
      next()
    } else {
      next({
        path: '/',
        // params: { nextUrl: to.fullPath }
      })
    }
  }
  if(to.matched.some(record => record.meta.AdminRole)) {
    if ((sessionStorage.getItem('token') != null || sessionStorage.getItem('jwt') != 'null') && Store.state.adminRole == 'Super Admin') {
      next()
    } else {
      next({
        path: '/',
        // params: { nextUrl: to.fullPath }
      })
    }
  }
  else {
    next()
  }
})

export default router
