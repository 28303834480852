<template>
  <v-app>
    <Header v-if="$store.state.isAuthenticated"></Header>
    <v-main>
      <router-view/>
    </v-main>
    <notifications position="top right" classes="my-custom-class vue-notification mt-3" duration="10" />
  </v-app>
</template>

<script>
import { APIService } from './Utils/APIService'
const apiService = new APIService()
import Header from './components/Header.vue';
export default {
  name: 'App',
  components: {
    Header
  },
  data: () => ({
    //
    errors:[]
  }),
  beforeMount() {
    if (sessionStorage.getItem('token') != null)
      this.checkAuth()
  },
  methods: {
    checkAuth() {
      apiService.checkAuth(sessionStorage.getItem('token')).then((response) => {
        this.$store.state.adminDetails.name = response.data.data.name
        this.$store.state.adminDetails.email = response.data.data.email
        this.$store.state.adminDetails.token = response.data.data.token
        this.$store.state.adminRole = response.data.data.admin_role[0].name
        this.$store.state.isAuthenticated = true
      })
        .catch(e => {
          this.errors.push(e)
          if (this.errors[0].response.status) {
            this.$store.state.isAuthenticated = false
            sessionStorage.removeItem('token')
            this.$router.push('/')
          }
        })
    },
  },
}
</script>
