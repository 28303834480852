<template>
  <!-- <v-card> -->
    <v-navigation-drawer
      expand-on-hover
      permanent
      rail
      color="blue-darken-4"
      elevation="0"
    >
      <v-list>
        <v-list-item
          :title="$store.state.adminDetails.name"
          :subtitle="$store.state.adminDetails.email"
        ></v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list density="compact" nav>
        <v-list-item prepend-icon="mdi-home" to="/" title="Home" value="home"></v-list-item>
        <v-list-item prepend-icon="mdi-account" to="/admins" title="Admins" value="admins" v-if="$store.state.adminRole == 'Super Admin'"></v-list-item>
        <v-list-item prepend-icon="mdi-book" to="/departments" title="Departments" value="departments"></v-list-item>
        <v-list-item prepend-icon="mdi-image" to="/event" title="Event Gallery" value="image"></v-list-item>
        <v-list-item prepend-icon="mdi-account-tie-outline" to="/non_teaching" title="Non Teaching Staff" value="nonTeachingStaff"></v-list-item>
        <v-list-item prepend-icon="mdi-face-agent" to="/contact" title="Student Queries" value="customer"></v-list-item>
        <v-list-item prepend-icon="mdi-image-area" to="/banners" title="Banners" value="banners"></v-list-item>
        <v-list-item prepend-icon="mdi-book-education" to="/scholarships" title="Scholarship" value="scholarships"></v-list-item>
        <v-list-item prepend-icon="mdi-pdf-box" to="/circulars" title="Circulars" value="circulars"></v-list-item>
        <v-list-item prepend-icon="mdi-file-chart" to="/iqac" title="IQAC" value="iqac"></v-list-item>
        <v-list-item prepend-icon="mdi-logout" title="Log Out" @click="logout()" value="logout"></v-list-item>
      </v-list>
    </v-navigation-drawer>
  <!-- </v-card> -->
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  methods: {
    logout() {
      sessionStorage.removeItem('token')
      this.$store.state.isAuthenticated = false
      this.$router.push('/login')
    }
  },
}
</script>